
import {Component, Prop, Vue, Model, Watch} from "vue-property-decorator";
import {AttachmentDto, AttachmentHostType} from "@/api/appService";
import api from '@/api/index'
import {UserModule} from "@/store/modules/user";
import {AppModule} from "@/store/modules/app";
import {interfaceTemplate} from "swagger-vue-codegen/dist/templates/template";

interface FileItem{
  id:number,
  name:string,
  url:string
}

@Component({name:"ImageList"})
export default class ImageList extends  Vue{

  @Prop({required:true})
  hostId!:string | number;

  @Prop({required:true})
  hostType!:AttachmentHostType;


  private existsFileList:FileItem[] = [];


  get headers(){
    return {
      'Abp.OrganizationUnitId':UserModule.getOU?.id,
      'Abp.TenantId':AppModule.getTenantId,
      'Authorization':`Bearer ${UserModule.getToken}`,
      '.AspNetCore.Culture':'c=zh-Hans|uic=zh-Hans'
    }
  }

  // @Watch('hostId')
  // onHostIdChange(){
  //   this.loadExistsAttachments();
  // }
  //
  // @Watch('hostType')
  // onHostTypeChange(){
  //   this.loadExistsAttachments();
  // }


  async created(){
    await this.loadExistsAttachments();
  }
  // convertImageToBase64(url: string): Promise<string> {
  //   return new Promise((resolve, reject) => {
  //     const xhr = new XMLHttpRequest();
  //     debugger
  //     xhr.open('GET', url, true);
  //     xhr.responseType = 'blob';
  //     xhr.withCredentials=true;
  //     xhr.onload = () => {
  //       debugger
  //       if (xhr.status === 200) {
  //         const blob = xhr.response;
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //           const base64String = reader.result as string;
  //           console.log(base64String)
  //           resolve(base64String);
  //         };
  //         reader.readAsDataURL(blob);
  //       } else {
  //         reject(new Error(`发生错误: ${xhr.statusText}`));
  //       }
  //     };
  //     xhr.onerror = (error) => {
  //       debugger
  //       reject(new Error('发生错误:' + error));
  //     };
  //     xhr.send();
  //   });
  // }

  async loadExistsAttachments(){
    await api.attachmentService.getAttachments({hostId:this.hostId.toString(),hostType:this.hostType}).then(res=>{
      if(res){
        this.existsFileList = res.map(attachment=>{
          return {
            id:attachment.id!,
            url:attachment.url!,
            name:attachment.fileName!
          }
        });
        // this.existsFileList.forEach((res)=>{
          // this.convertImageToBase64(res.url)
          //   .then((base64String) => {
          //     res.url=base64String;
          //   })
          //   .catch((error) => {
          //     console.error('发生错误:', error);
          //   });
        // })
      } else {
        this.existsFileList = [];
      }
    })
  }


}

