
  import {Component, Model, Prop, Vue, Watch} from "vue-property-decorator";
  import {resolveSelectOptions} from "@/components/CustomForm/common";
  import {CustomFormFieldCreateOrUpdateDto, PermissionDefinitionScope, SelectListItem} from "@/api/appService";

  @Component({
    name: "MultipleControl"
  })
  export default class MultipleControl extends Vue {
    @Prop({required: false})
    hostData?: any;

    @Prop({required: false})
    control!:CustomFormFieldCreateOrUpdateDto;

    private selectList:SelectListItem[] = [];

    value: string|undefined = '';

    created(){
      this.initSelectOptions()
    }

    private initSelectOptions(){
      if(this.control.dataSource){
        resolveSelectOptions(this.control).then(res=>{
          this.selectList = res;
          let valueList:any[] = [];
          let hostData:string[] = []
          if(!(this.hostData instanceof Array) && this.hostData) {
            hostData = this.hostData.split(',')
          }
          res.filter((role:any)=>{
            hostData.map(item=>{
              if(role.value == item) {
                valueList.push(role.text)
              }
            })
          })
          this.value = valueList.join(',')
        })
      }
    }



  }
