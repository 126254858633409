

  import {Component, Prop, Vue} from "vue-property-decorator";
  import api from '@/api';
  import {CustomFormDto, CustomFormLayoutDetailDto, CustomFormType} from "@/api/appService";
  import {ILayoutItem} from "@/components/CustomForm/common";
  import TableRowItem from "@/components/CustomForm/TableDetail/TableRowItem.vue";
  import axios from '@/utils/request';


  interface StandardDto {
    id:string | number;
    extensionObject?:Object;
  }

  @Component({
    name:'CustomFormDataTableDetail',
    components:{
      TableRowItem
    }
  })
  export default class CustomFormDataTableDetail extends Vue{


    @Prop({required:true,default:''})
    hostType!:CustomFormType;

    @Prop({required:false,default:''})
    dataId!:string | number;

    @Prop({required:false,default:0})
    formId!:number;

    @Prop({required:false,default:false})
    useDataIdAsHostId!:boolean;

    @Prop({required:false})
    getDataPromise?:Promise<StandardDto>;

    @Prop({required:false})
    url?:string;

    colSize = 5;

    private customForm!:CustomFormDto;
    private dataDto:any = {};
    private layoutDetails:ILayoutItem[] = [];

    private loading = true;

    private customFormId!:number;
    private emptyTdList:number[] = [];

    async created(){
      if(this.formId){
        this.customFormId = this.formId;
      } else if(this.hostType){
        const filter = {hostType:this.hostType,hostId:''};
        if(this.useDataIdAsHostId){
          filter.hostId = this.dataId.toString();
        }
        await api.customFormService.getAll(filter).then(res=>{
          if(res.items && res.items.length){
            this.customFormId = res.items[0].id!;
          }
        })

      } else {
        this.$message.error('组件参数不正确');
        throw '组件参数不正确'
      }

      if(!this.customFormId){
        this.$message.error('未找到对应的自定义表单');
        throw '未找到对应的自定义表单';
      }
      await this.loadCustomForm();

      if(this.dataId){
        if(this.url){
          await this.loadDataUseUrl();
        } else {
          await this.loadData();
        }
      }

    }

    private async loadCustomForm(){
      await api.customFormService.get({id:this.customFormId}).then(res=>{
        this.customForm = res;
        const rootList = res.layout!.filter(s=>s && !s.parentId);
        this.layoutDetails = this.buildLayoutDetailFromDto(rootList,res);
        console.log(this.layoutDetails,'layoutDetails')
        this.colSize = this.calcColSize();
        const tdWidth = 100 / this.colSize;
        this.emptyTdList = [];
        for(let i = 0;i<this.colSize;i++){
          this.emptyTdList.push(tdWidth);
        }
      })
    }

    private async loadDataUseUrl(){
      await axios.get(this.url!,{params:{id:this.dataId}}).then(res=>{
        this.dataDto = {...res};
        this.loading = false;
      })
    }

    private async loadData(){
      console.log('loadData')
      await this.getDataPromise!.then(res=>{

        this.dataDto = {...res};
        this.loading = false;
      })
    }

    private buildLayoutDetailFromDto(layouts:CustomFormLayoutDetailDto[],dto:CustomFormDto,layoutItem?:ILayoutItem){

      if(!layouts || !layouts.length){
        return [];
      }
      const result :ILayoutItem[] =[];
      layouts.forEach(item=>{
        const newLayoutItem:ILayoutItem = {
          data:item,
          formControl:undefined
        };
        let control = dto.fields!.filter(s=>s && s.layoutId === item.id);
        if(control && control.length){
          newLayoutItem.formControl = control[0];
        }
        const children = dto.layout!.filter(s=>s && s.parentId === item.id);
        newLayoutItem.children = this.buildLayoutDetailFromDto(children,dto,newLayoutItem);

        result.push(newLayoutItem);
      });

      return result;

    }

    private calcColSize(){

      const temp:any = {};
      this.layoutDetails.forEach(item=>{
        const length = item.children?.length??0;
        if(length !== 0){
          if(!temp[length]){
            temp[length] = 5;
          }
        }
      });

      let value = 10;
      for(let key in temp){
        value *= parseInt(key);
      }

      return 24;

    }




  }
